var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v(_vm._s(_vm.formTitle)),
      ]),
      _c("vx-card", [
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:100",
                    expression: "'required|max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Name" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Restrictions")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:500",
                    expression: "'required|max:500'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Restrictions" },
                model: {
                  value: _vm.form.restrictions,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "restrictions", $$v)
                  },
                  expression: "form.restrictions",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Restrictions"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Estimated Delivery Days")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: "Estimated Delivery Days",
                  options: _vm.deliveryEstimateOptions,
                  reduce: (x) => x.code,
                },
                model: {
                  value: _vm.form.estimateDays,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "estimateDays", $$v)
                  },
                  expression: "form.estimateDays",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Estimated Delivery Days"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Price")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|decimal",
                    expression: "'required|decimal'",
                  },
                ],
                staticClass: "w-full",
                attrs: { type: "number", min: "0", name: "Price" },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", _vm._n($$v))
                  },
                  expression: "form.price",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Price"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Currency")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: "Currency",
                  options: _vm.currencyOptions,
                  reduce: (x) => x.code,
                },
                model: {
                  value: _vm.form.currency,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "currency", $$v)
                  },
                  expression: "form.currency",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Currency"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Active")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
            [
              _c("vs-switch", {
                model: {
                  value: _vm.form.isActive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isActive", $$v)
                  },
                  expression: "form.isActive",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "danger", type: "filled" },
                on: { click: _vm.onCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-2",
                attrs: { color: "primary", type: "filled" },
                on: { click: _vm.onSave },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }